<template>
  <div class="wp-textarea">
    <el-input
      class="paper-textarea"
      :disabled="readOnly"
      :placeholder="placeholder"
      v-model="inputValue"
      :rows="rows"
      type="textarea"
    >
      <span>333</span>
    </el-input>
    <span class="wp-ispan" @click="openDialog" v-if="!readOnly">习惯用语</span>
  </div>
  <el-dialog
    v-model="DialogVisible"
    width="55%"
    custom-class="wrap-idiom"
    :show-close="false"
  >
    <template #title>
      <div class="card-header">
        <span
          >{{
            taskType === "none" ? "选择" : taskType === "add" ? "新增" : "修改"
          }}习惯用语</span
        >
        <div>
          <el-button
            size="medium"
            type="success"
            @click="clearIdiom('add')"
            v-show="taskType === 'none' && selectTabValue == '0'"
            >新增</el-button
          >
        </div>
      </div>
    </template>
    <el-tabs
      v-model="selectTabValue"
      @tab-click="clearIdiom"
      type="border-card"
      v-show="taskType === 'none'"
    >
      <el-tab-pane
        :label="item.label"
        v-for="(item, index) in tabData"
        :key="index"
      >
        <el-table
          :data="item.tableData"
          style="width: 100%"
          :height="'250'"
          :border="true"
          highlight-current-row
          @row-click="handleCurrentChange"
          header-cell-class-name="wp-header"
        >
          <!-- <el-table-column
            type="index"
            prop="num"
            label="编号"
            width="60"
            label-class-name="wp-tab"
          /> -->
          <el-table-column prop="Content" label="习惯用语">
            <template #default="scope">
              <div style="white-space: pre">
                {{ scope.row.Content }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="Content"
            label="操作"
            :width="100"
            v-if="selectTabValue == '0'"
          >
            <template #default="scope">
              <el-button size="mini" type="text" @click="changeIdiom(scope.row)"
                >修改</el-button
              >
              <el-button size="mini" type="text" @click="deleteIdiom(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <el-form
      :model="form"
      label-position="left"
      label-width="120px"
      style="margin-top: 15px"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="习惯用语内容:">
            <el-input
              v-model="form.Content"
              type="textarea"
              :placeholder="taskType === 'none' ? '习惯用语内容' : '请输入内容'"
              :readonly="taskType === 'none'"
              rows="3"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="wp-btn">
      <div></div>
      <div>
        <el-button size="medium" type="primary" @click="onSubmit"
          >确定</el-button
        >
        <el-button size="medium" @click="cancel">取消</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
  props: {
    readOnly: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '请输入内容',
    },
    rows: {
      type: [Number],
      default: 4,
    },
  },
  watch: {
    value() {
      this.inputValue = this.value;
    }
  },
  data() {
    return {
      taskType: 'none',
      inputValue: this.value,
      DialogVisible: false,
      tabData: [
        {
          label: "个人习惯用语",
          tableData: [],
        },
        {
          label: "业务习惯用语",
          tableData: [],
        },
      ],
      form: {
        num: 0,
        Content: ''
      },
      options: [
        { label: "个人习惯用语", value: "0" },
        { label: "业务习惯用语", value: "1" },
      ],
      selectTabValue: '0'
    };
  },
  methods: {
    changeIdiom(val) {
      this.taskType = 'update'
      this.form = val;
    },
    openDialog() {
      this.DialogVisible = true;
    },
    // 选中行
    handleCurrentChange(val) {
      val = JSON.parse(JSON.stringify(val));
      this.form = val;
    },
    getXGYU() {
      let me = this;
      this.$ajax.get(this.$appConfig.apiUrls.ywxtApi + "/api/YW/GetAllXGYU?type=0")
        .then((res) => {
          if (res.data.IsSuccess) {
            me.tabData[0].tableData = res.data.Data;
          } else {
            console.log('获取个人习惯用语失败!');
          }
        })
      this.$ajax.get(this.$appConfig.apiUrls.ywxtApi + "/api/YW/GetAllXGYU?type=1")
        .then((res) => {
          if (res.data.IsSuccess) {
            me.tabData[1].tableData = res.data.Data;
          } else {
            console.log('获取业务习惯用语失败!');
          }
        })
    },
    clearIdiom(type) {
      if (type === 'add') {
        this.taskType = type;
      }
      this.form = {
        num: 0,
        Content: ''
      }
    },
    deleteIdiom(value) {
      ElMessageBox.confirm(`确定要删除当前习惯用语吗？`, '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$ajax.post(
          this.$appConfig.apiUrls.ywxtApi + "/api/YW/DeleteXGYU?Id=" + value.Id
        ).then((r) => {
          if (r.data.IsSuccess) {
            ElMessage.success('操作成功');
            this.getXGYU();
            this.clearIdiom();
          } else {
            ElMessage.error({ message: '操作失败' })
          }
        })
      }).catch(() => {
        ElMessage({
          type: 'info',
          message: '已取消操作',
        })
      })
    },
    getValue() {
      return this.inputValue;
    },
    onSubmit() {
      if (this.taskType === 'none') {
        if (this.form.Content == '') {
          this.DialogVisible = false;
        } else {
          this.inputValue = this.form.Content;
          this.DialogVisible = false;
        }
      } else {
        if (this.form.Content == '') {
          ElMessage.warning('习惯用语内容不能为空!')
        } else {
          let obj = {
            Id: this.form.Id ? this.form.Id : '',
            content: this.form.Content,
            userId: this.form.UserId ? this.form.UserId : this.$store.state.userInfo.UserId,
            type: this.selectTabValue
          }
          obj = JSON.stringify(obj)
          this.$ajax.post(this.$appConfig.apiUrls.ywxtApi + "/api/YW/AddorUpdateXGYU", obj)
            .then((res) => {
              if (res.data.IsSuccess) {
                ElMessage.success('操作成功')
                this.taskType = 'none'
                this.getXGYU();
                this.clearIdiom();
              } else {
                ElMessage.error('操作失败')
              }
            })
        }
      }
    },
    cancel() {
      if (this.taskType === 'none') {
        this.DialogVisible = false;
      } else {
        this.taskType = 'none';
        this.form = {
          num: 0,
          Content: ''
        }
      }

    }
  },
  mounted() {
    this.getXGYU()
  },
};
</script>

<style scoped>
.wp-textarea {
  display: flex;
  align-items: center;
  border: 1px solid #e5e6e9;
  border-radius: var(--el-border-radius-base);
  background: #f5f7fa;
}
.wp-ispan {
  font-size: 12px;
  display: inline-block;
  width: 62px;
  padding: 5px;
  cursor: pointer;
}
.wp-ispan:hover {
  color: var(--el-color-primary);
}
.paper-textarea {
  width: 100%;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
}
.card-header span {
  font-size: 18px;
  /* font-weight: bold; */
}

:deep(.wp-header) {
  color: rgb(0, 0, 0);
  padding: 6px 0 !important;
  box-shadow: inset 0px 0px 0px 0px #000, inset 3px -5px 13px -15px #000;
}
:deep(.el-table) {
  border: 1px solid #b7b5b5;
}
:deep(.current-row td:first-of-type) {
  border: 1px solid var(--el-color-primary);
}
.wp-btn {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
.wp-btn div:last-of-type {
  margin-right: 20px;
}
.wrap-idiom :deep(.el-table th),
.wrap-idiom :deep(.el-table td) {
  padding: 14px 0;
}
</style>
