<template>
  <div class="wrap-edit">
    <el-card>
      <FloatTitBtn>
        <div class="edit-header">
          <span>材料接受凭证</span>
          <div>
            <el-button type="primary" v-if="!readOnly" @click="onSubmit">保存</el-button>
            <el-button type="warning" @click="print">打印</el-button>
          </div>
        </div>
      </FloatTitBtn>

      <div class="edit-container">
        <el-form
            ref="baseform"
            :model="form"
            label-width="120px"
            :label-position="labelPosition"
        >
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="建设单位:">
                <el-input v-model="form.JSDW" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收件人:">
                <el-input v-model="form.JBRXM" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收件日期:">
                <el-date-picker
                    disabled
                    style="width: 100%"
                    v-model="form.TXRQ"
                    placeholder="请选择接件日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="材料:">
                <NewTextarea ref="textarea" :readOnly="readOnly" :value="form.CLSM" :rows="10"></NewTextarea>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
    <div id="printable-form" style="font-family: 'SimSun'">
      <h1 style="font-size: 25px;font-weight: 400;margin-top: -50px;margin-bottom: 50px">材料接受凭证</h1>
      <p style="font-size: 14px;margin-left: 80px">
        建设单位 <span style="margin-left: 10px">{{ form.JSDW }}</span>
      </p>
      <div style="display: flex;min-height: 300px;margin-left: 80px">
        <p style="font-size: 14px">材料：</p>
        <div style="margin-left: 15px">
          <p v-for="(v, i) in cllist" :key="i" style="font-size: 14px" class="wrap-cl">
            <span>{{ v }}{{v?';':''}}</span>
          </p>
        </div>
      </div>
      <div style="display: flex;justify-content: space-between;margin-top: 20px">
        <p style="font-size: 14px;margin-left: 80px">
          收件人签字或盖章
        </p>
        <p style="font-size: 14px;margin-right: 80px">
          收件人 <span>{{ form.JBRXM }}</span>
        </p>
      </div>
      <div style="display: flex;justify-content: space-between">
        <p style="font-size: 14px;margin-left: 80px">
          收件日期：
        </p>
        <p style="font-size: 14px;margin-right: 80px">
          收件日期：<span>{{ (form.TXRQ + "")?.split("T")[0] }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {ElMessage} from 'element-plus'
import print from "print-js";
import mgr from "@/services/security.js";
import FloatTitBtn from '../../components/FloatTitBtn.vue';
import NewTextarea from '@/components/Input/NewTextarea.vue'

export default {
  name:'jspz',
  components: {FloatTitBtn, NewTextarea},
  data() {
    return {
      readOnly: true,
      labelPosition: 'left',
      form: {
        Id: '',
        BJID: '',
        JSDW: '',
        JBRXM: '',
        TXRQ: '',
        CLSM: '',
        JBR: '',
      },
      cllist: ['', '', '']
    }
  },
  methods: {
    async onSubmit() {
      this.form.CLSM = this.$refs.textarea.getValue();
      let me = this
      console.log(me.form)
      if (me.checkform()) {
        await this.$ajax
            .post(
                me.$appConfig.apiUrls.ywxtApi + '/api/YW/AddOrUpdatecljspz',
                me.form
            )
            .then(function (response) {
              if (response.data.IsSuccess) {
                me.form.Id = response.data.Data
                me.cllist = me.form.CLSM.split('\n')
                ElMessage({
                  type: 'success',
                  message: '保存成功',
                })
              } else {
                ElMessage({
                  type: 'info',
                  message: response.data.errorMessage,
                })
                console.log(response.data.errorMessage)
              }
            })
      }
    },
    async switch() {
      let me = this
      me.form.BJID = this.$route.query.instanceId
      await this.$ajax
          .get(
              me.$appConfig.apiUrls.ywxtApi +
              '/api/YW/Getcljspz?BJID=' +
              me.form.BJID
          )
          .then(function (response) {
            if (response.data.IsSuccess) {
              me.form = response.data.Data
              if (response.data.Data.CLSM != null && response.data.Data.CLSM.trim() != '') {
                me.cllist = me.form.CLSM.split('\n')
              }
            } else {
              ElMessage({
                type: 'info',
                message: '初始化表单失败',
              })
              console.log(response.data.errorMessage)
            }
          })

      const user = await mgr.getUser();
      if (this.form.JBRXM === user.profile.name && this.$route.query.taskId) {
        this.readOnly = false;
        if(this.form.Id == ''){
        this.$ajax
            .post(
                me.$appConfig.apiUrls.ywxtApi +
                '/api/File/GetFileList?projectType=' +
                me.$route.query.fid
            )
            .then(function (response) {
              if (response.data) {
                let cllist = new Array()
                let data = JSON.parse(response.data.Data)[0]
                let parentNo = 1
                data.children.forEach(element => {
                  cllist.push(parentNo + '.' + element.name)
                  if (element.children) {
                    let childNo = 1
                    element.children.forEach(child => {
                      cllist.push('  ' + parentNo + '.' + childNo + '.' + child.name)
                      childNo++
                    })
                  }
                  parentNo++
                })
                me.form.CLSM = cllist.join('\n')
                me.cllist = cllist
              }
            })
        }   
      }
    },
    async print() {
      let me = this
      await this.$ajax
          .get(
              me.$appConfig.apiUrls.ywxtApi +
              '/api/YW/setcljspzdysj?BJID=' +
              me.form.BJID
          )
          .then(function () {
            print({
              printable: "printable-form",
              type: "html",
              scanStyles: false,
              css: "/static/print-style.css",
              documentTitle: "",
            });
          })
    },
    checkform() {
      let b = true
      let f = this.form
      if (b) b = this.checkinput(f.CLSM, '材料信息')
      return b
    },
    checkinput(val, mess) {
      if (!val || val.length < 1) {
        ElMessage({
          type: 'info',
          message: '请输入' + mess,
        })
        return false
      } else {
        return true
      }
    },
  },
  mounted() {
    this.switch()
    console.log(this.$store.state.currentStepName)
  },
}
</script>

<style scoped>
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-form {
  width: 100%;
  overflow: hidden;
}

.input-width {
  width: 100%;
}

.wrap-edit .edit-header {
  margin-bottom: 15px;
}

#printable-form {
  display: none;
}
</style>
